import AboutPage from '../components/AboutPage';
import AOS from 'aos'
import { graphql } from 'gatsby'
import Layout from '../components/Layout';
import React, { PureComponent } from 'react'
import withLoading from '../hocs/withLoading'

import styles from './styles-about.module.css'

class About extends PureComponent {
  componentDidMount = () => {
    AOS.init({
      duration: 700,
      offset: 120,
    })
  }

  render() {
    const { pageContext, data, loading } = this.props

    return (
      <Layout pageContext={pageContext} loading={loading} location={this.props.location}>
        <div className={styles.background}>
          <AboutPage content={data.cockpitAboutPage} loading={loading} />
        </div>
      </Layout>
    )
  }
}

export default withLoading(About)

export const query = graphql`
    query {
        cockpitAboutPage(cockpitId: { eq: "5d42fae074d1a200ce0a3c92" }) {
            primary {
              value
            }
            secondary {
              value
            }
            description {
              value {
                childMarkdownRemark {
                  html
                  htmlAst
                }
              }
            }
            image {
              value {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
    }
`