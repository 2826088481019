import Img from 'gatsby-image'
import Quote from '../Quote'
import HelpLink from '../Help/HelpLink'
import React, { Component } from 'react'
import RehypeReact from 'rehype-react'

import styles from './styles.module.css'

class AboutPage extends Component {
    render() {
        const { content } = this.props

        return (
            <div className={styles.aboutPage}>
                <div className={styles.imageSection}>
                    <div className={styles.imageSubsection}>
                        <Img
                            critical
                            className={styles.image}
                            fluid={content.image.value.childImageSharp.fluid}
                            alt="Header picture"
                            onStartLoad={this.props.loading.register}
                            onLoad={this.props.loading.notify}
                        />
                    </div>
                </div>
                <div className={styles.textSection}>
                    <div
                        data-aos="fade-up"
                        data-aos-duration="1200"
                        className={styles.titleContainer}
                    >
                        <div className={styles.title}>
                            {content.primary.value.toUpperCase()}
                        </div>
                        <div className={styles.secondaryTitle}>
                            {content.secondary.value.toUpperCase()}
                        </div>
                        <hr className={styles.line} />
                        <div className={styles.description}>
                            {new RehypeReact({
                                createElement: React.createElement,
                                components: {
                                    quote: Quote(styles.quote),
                                    helplink: HelpLink()
                                },
                            }).Compiler(content.description.value.childMarkdownRemark.htmlAst)
                            }
                        </div>
                        <hr className={styles.line} />
                    </div>
                </div>
            </div>
        )
    }
}

export default AboutPage